import React, { useContext, useLayoutEffect } from 'react';
import { PolicyStyled } from './Policy.styled';
import { local } from '../../../local';
import { GlobalContext } from '../../layout/Layout';
import logo from '../../../assets/images/Logo.svg';
import { useWindowSize } from '../../../hooks/useWindowResize.hook';
import disableScroll from 'disable-scroll';

const Policy = ({ isChecked, setIsChecked, handleConfirm }) => {
    const { state } = useContext(GlobalContext);

    const { windowHeight } = useWindowSize();
    useLayoutEffect(() => {
        disableScroll.on();
    }, []);
    return (
        <PolicyStyled windowWidth={windowHeight}>
            <div className="policy position-relative h-100">
                <div className="row align-items-md-center h-100">
                    <div className="col-12 col-md-8 col-lg-6">
                        <div className="content-wrapper">
                            <img src={logo} alt="logo" className="d-inline-block logo" />
                            <p>{local[state.languageId].privacyConfirmMessage}</p>
                            <span className="d-inline-block">
                                {local[state.languageId].ageConfirm}
                            </span>
                            <div className="button-container">
                                <button
                                    className="button text-uppercase d-inline-block"
                                    onClick={handleConfirm}
                                >
                                    {local[state.languageId].yes}
                                </button>
                                <button className="button negative text-uppercase d-inline-block">
                                    <a href="https://c.tenor.com/8qtOK6_iNRMAAAAC/proud-ron-swanson.gif">
                                        {local[state.languageId].no}
                                    </a>
                                </button>
                            </div>
                            <input
                                className="styled-checkbox"
                                id="styled-checkbox-1"
                                type="checkbox"
                                checked={isChecked}
                                onChange={() => {
                                    setIsChecked(!isChecked);
                                }}
                            />
                            <label htmlFor="styled-checkbox-1">
                                {local[state.languageId].remember}
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="pattern" />
        </PolicyStyled>
    );
};

export default Policy;
