import styled from 'styled-components';
import checked from '../../../assets/images/checked.svg';

import patternDesktop from '../../../assets/images/pattern.png';
import patternMobile from '../../../assets/images/popup-pattern.png';

export const PolicyStyled = styled.div`
    position: fixed;
    inset: 0 0;
    background-color: #090909;
    z-index: 200;
    height: ${({ windowHeight }) => windowHeight + 100 + 'px'};
    padding: 0 25px;

    @media (min-width: 1280px) {
        padding: 0 0 0 200px;
    }

    .policy {
        z-index: 3;

        p {
            font-size: 16px;
            color: #bbbbbb;
            margin-bottom: 30px;
            @media (min-width: 1440px) {
                font-size: 24px;
            }
            @media (min-width: 1920px) {
                font-size: 28px;
                max-width: 650px;
            }
        }

        span {
            font-size: 22px;
            color: #fff;
            margin-bottom: 40px;
            @media (min-width: 1440px) {
                font-size: 26px;
            }
            @media (min-width: 1920px) {
                font-size: 32px;
            }
        }

        .logo {
            width: 150px;
            margin-bottom: 30px;
            filter: brightness(2);

            @media (min-width: 1920px) {
                width: 350px;
                margin-bottom: 50px;
            }
        }

        .content-wrapper {
            margin-top: 60px;
            @media (min-width: 768px) {
                margin-top: 0;
                padding: 70px;
            }
            @media (min-width: 1280px) {
                padding: 70px;
                background-color: #000;
                margin-top: 0;
            }
            @media (min-width: 1440px) {
                padding: 70px;
            }
            @media (min-width: 1920px) {
                padding: 100px;
            }
        }

        .button-container {
            margin-bottom: 40px;

            .button {
                margin-right: 20px;
                width: 110px;
            }
        }

        .styled-checkbox {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it

            & + label {
                position: relative;
                cursor: pointer;
                padding: 0;
                transition: all 0.2s ease-in-out;
                color: rgba(255, 255, 255, 0.5);
            }

            // Box.
            & + label:before {
                content: '';
                margin-right: 15px;
                display: inline-block;
                vertical-align: middle;
                width: 30px;
                height: 30px;
                background-color: transparent;
                border: 2px solid rgba(255, 255, 255, 0.5);
                transition: all 0.2s ease-in-out;
            }

            & + label:after {
                opacity: 0;
                content: '';
                position: absolute;
                inset: 0 0;
                background: url(${checked}) no-repeat 7px 9px / 55%;
                width: 30px;
                height: 30px;
                transition: all 0.2s ease-in-out;
            }

            &:checked + label {
                color: #fff;
            }

            &:hover + label,
            &:hover + label:before,
            &:checked + label:before {
                border-color: #fff;
                color: #fff;
            }

            &:checked + label:after {
                opacity: 1;
            }
        }
    }

    //.pattern {
    // position: absolute;
    // top: 0;
    // right: 30px;
    // bottom: 0;
    // width: 140px;
    // height: 100%;
    // background: url() top / 300%;
    //
    // @media (min-width: 1280px) {
    //     right: 190px;
    //     width: 400px;
    //     background: url($) top / 100%;
    // }
    .pattern {
        position: absolute;
        top: 0;
        right: 30px;
        bottom: 0;
        width: 140px;
        height: 100%;
        background: url(${patternMobile}) top left / 130%;
        @media (min-width: 768px) {
            width: 200px;
            right: 90px;
        }
        @media (min-width: 1280px) {
            right: 150px;
            width: 300px;
            background: url(${patternDesktop}) top / 100%;
        }
        @media (min-width: 1440px) {
            right: 170px;
        }
        @media (min-width: 1920px) {
            right: 190px;
            width: 400px;
        }
    }

    //}
`;
