import React, { useState, useContext, useRef, useEffect, useLayoutEffect } from 'react';
import logo from '../../../assets/images/Logo.svg';
import { HeaderStyled } from './Header.styles';
import { NavLink, Link, useLocation, useParams } from 'react-router-dom';

import hamburger from '../../../assets/images/hamburger.svg';
import close from '../../../assets/images/close-button.svg';
import { GlobalContext } from '../Layout';
import { local } from '../../../local';
import { useWindowSize } from '../../../hooks/useWindowResize.hook';

const Header = () => {
    const [openHamburger, setOpenHamburger] = useState(false);
    const menuRef = useRef();
    const { pathname } = useLocation();
    let match = useParams();
    const { windowWidth } = useWindowSize();
    const { state, setState } = useContext(GlobalContext);
    const [activeNav, setActiveNav] = useState('main');

    const handleNavigateToSection = (section, sectionName) => () => {
        setOpenHamburger(false);
        setActiveNav(sectionName);
        window.scrollTo({
            top: section.current?.offsetTop,
            behavior: 'smooth',
        });
    };

    const handleNavigateFromOtherRoutes = (sectionId) => () => {
        setOpenHamburger(false);
        // setState({ ...state, activeNav: sectionId });
    };

    useEffect(() => {
        if (menuRef.current && pathname !== '/') {
            for (let i = 0; i < menuRef.current.children.length; i++) {
                menuRef.current.children[i].className = '';
            }
        }
        // console.log(state.refList[state.activeNav]);
        // if (pathname === '/' && windowWidth < 768 && state.refList[state.activeNav]) {
        //     handleNavigateToSection(state.refList[state.activeNav], state.activeNav)();
        // }
    }, [pathname]);

    return (
        <HeaderStyled className="d-flex align-items-center">
            <div className="header-wrapper d-flex justify-content-between align-items-lg-start w-100">
                <Link
                    to="/"
                    className="logo"
                    onClick={() => {
                        window.scrollTo({
                            top: 0,
                            behavior: 'smooth',
                        });
                    }}
                >
                    <img src={logo} alt="logo" />
                </Link>
                <nav className={openHamburger ? 'active' : ''}>
                    <div className="d-flex align-items-center justify-content-between d-lg-none logo-container">
                        <Link
                            to="/"
                            className="logo d-inline-block"
                            onClick={() => {
                                setOpenHamburger(false);
                            }}
                        >
                            <img src={logo} alt="logo" />
                        </Link>
                        <img
                            src={close}
                            alt="hamburger"
                            onClick={() => {
                                setOpenHamburger(false);
                            }}
                        />
                    </div>
                    <div className="pattern d-lg-none" />
                    <ul
                        id="menu"
                        className="d-flex flex-column flex-lg-row text-uppercase"
                        ref={menuRef}
                    >
                        <li data-menuanchor="main" className={activeNav === 'main' ? 'active' : ''}>
                            {pathname === '/' ? (
                                <a
                                    href="#main"
                                    onClick={
                                        windowWidth < 1280
                                            ? handleNavigateToSection(state.refList.mainRef, 'main')
                                            : () => setOpenHamburger(false)
                                    }
                                >
                                    {local[state.languageId].main}
                                </a>
                            ) : (
                                <NavLink
                                    to="/#main"
                                    onClick={handleNavigateFromOtherRoutes('mainRef')}
                                >
                                    {local[state.languageId].main}
                                </NavLink>
                            )}
                        </li>
                        <li data-menuanchor="box" className={activeNav === 'box' ? 'active' : ''}>
                            {pathname === '/' ? (
                                <a
                                    href="#box"
                                    onClick={
                                        windowWidth < 1280
                                            ? handleNavigateToSection(state.refList.boxRef, 'box')
                                            : () => setOpenHamburger(false)
                                    }
                                >
                                    {local[state.languageId].box}
                                </a>
                            ) : (
                                <NavLink
                                    to="/#box"
                                    onClick={handleNavigateFromOtherRoutes('boxRef')}
                                >
                                    {local[state.languageId].box}
                                </NavLink>
                            )}
                        </li>
                        <li
                            data-menuanchor="business"
                            className={activeNav === 'business' ? 'active' : ''}
                        >
                            {pathname === '/' ? (
                                <a
                                    href="#business"
                                    onClick={
                                        windowWidth < 1280
                                            ? handleNavigateToSection(
                                                  state.refList.businessRef,
                                                  'business',
                                              )
                                            : () => setOpenHamburger(false)
                                    }
                                >
                                    {local[state.languageId].business}
                                </a>
                            ) : (
                                <NavLink
                                    to="/#business"
                                    onClick={handleNavigateFromOtherRoutes('businessRef')}
                                >
                                    {local[state.languageId].business}
                                </NavLink>
                            )}
                        </li>
                        <li
                            data-menuanchor="product"
                            className={activeNav === 'product' ? 'active' : ''}
                        >
                            {pathname === '/' ? (
                                <a
                                    href="#product"
                                    onClick={
                                        windowWidth < 1280
                                            ? handleNavigateToSection(
                                                  state.refList.productRef,
                                                  'product',
                                              )
                                            : () => setOpenHamburger(false)
                                    }
                                >
                                    {local[state.languageId].product}
                                </a>
                            ) : (
                                <NavLink
                                    to="/#product"
                                    onClick={handleNavigateFromOtherRoutes('productRef')}
                                >
                                    {local[state.languageId].product}
                                </NavLink>
                            )}
                        </li>
                        <li
                            data-menuanchor="about"
                            className={activeNav === 'about' ? 'active' : ''}
                        >
                            {pathname === '/' ? (
                                <a
                                    href="#about"
                                    onClick={
                                        windowWidth < 1280
                                            ? handleNavigateToSection(
                                                  state.refList.aboutRef,
                                                  'about',
                                              )
                                            : () => setOpenHamburger(false)
                                    }
                                >
                                    {local[state.languageId].aboutUs}
                                </a>
                            ) : (
                                <NavLink
                                    to="/#about"
                                    onClick={handleNavigateFromOtherRoutes('aboutRef')}
                                >
                                    {local[state.languageId].aboutUs}
                                </NavLink>
                            )}
                        </li>
                        <li
                            data-menuanchor="contacts"
                            className={activeNav === 'contacts' ? 'active' : ''}
                        >
                            {pathname === '/' ? (
                                <a
                                    href="#contacts"
                                    onClick={
                                        windowWidth < 1280
                                            ? handleNavigateToSection(
                                                  state.refList.contactsRef,
                                                  'contacts',
                                              )
                                            : () => setOpenHamburger(false)
                                    }
                                >
                                    {local[state.languageId].contacts}
                                </a>
                            ) : (
                                <NavLink
                                    to="/#contacts"
                                    onClick={handleNavigateFromOtherRoutes('contactsRef')}
                                >
                                    {local[state.languageId].contacts}
                                </NavLink>
                            )}
                        </li>
                    </ul>
                    <NavLink
                        to="/store"
                        className={({ isActive }) =>
                            `button-outline text-uppercase text-center d-inline-block d-lg-none ${
                                !match.id && isActive ? 'active' : ''
                            }`
                        }
                        onClick={() => setOpenHamburger(false)}
                    >
                        {local[state.languageId].store}
                    </NavLink>
                    <div className="languages d-lg-none">
                        <div
                            className={`${state.languageId === 'en' ? 'active' : ''}`}
                            onClick={() => {
                                setState({ ...state, languageId: 'en' });
                                localStorage.setItem('languageId', 'en');
                                setOpenHamburger(false);
                            }}
                        >
                            ENG
                        </div>
                        <div
                            className={`${state.languageId === 'hy-AM' ? 'active' : ''}`}
                            onClick={() => {
                                setState({ ...state, languageId: 'hy-AM' });
                                localStorage.setItem('languageId', 'hy-AM');
                                setOpenHamburger(false);
                            }}
                        >
                            ՀԱՅ
                        </div>
                    </div>
                </nav>
                <div className="align-items-center d-none d-lg-flex">
                    <NavLink
                        to="/store"
                        className={({ isActive }) =>
                            `button text-uppercase text-center d-inline-block ${
                                !match.id && isActive && 'active'
                            }`
                        }
                    >
                        {local[state.languageId].store}
                    </NavLink>
                    <div className="languages">
                        <div
                            className={`mb-1 ${state.languageId === 'en' ? 'active' : ''}`}
                            onClick={() => {
                                setState({ ...state, languageId: 'en' });
                                localStorage.setItem('languageId', 'en');
                            }}
                        >
                            ENG
                        </div>
                        <div
                            className={`${state.languageId === 'hy-AM' ? 'active' : ''}`}
                            onClick={() => {
                                setState({ ...state, languageId: 'hy-AM' });
                                localStorage.setItem('languageId', 'hy-AM');
                            }}
                        >
                            ՀԱՅ
                        </div>
                    </div>
                </div>
                <img
                    src={hamburger}
                    alt="hamburger"
                    className=" d-lg-none hamburger"
                    onClick={() => {
                        setOpenHamburger(true);
                    }}
                />
            </div>
        </HeaderStyled>
    );
};

export default Header;
