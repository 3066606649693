export const local = {
    en: {
        store: 'Store',
        main: 'Home',
        box: 'Box',
        business: 'Business',
        product: 'Products',
        aboutUs: 'About us',
        contacts: 'Contacts',
        whoAreYou: 'Who are you',
        more: 'More',
        phone: 'Phone',
        address: 'address',
        email: 'Email',
        copyright: 'All rights reserved',
        order: 'order',
        currency: 'AMD',
        nameSurname: 'Name Surname',
        deliveryAddress: 'Delivery Address',
        comment: 'Comment',
        notRequired: 'Not Required',
        send: 'send',
        successMessage: 'Our sales specialist will contact you shortly.',
        thanks: 'Thank you',
        starting: 'Starting',
        privacyConfirmMessage: 'Please confirm that you are an adult to see the brand.',
        ageConfirm: 'Are you 18 years old?',
        yes: 'Yes',
        no: 'No',
        remember: 'Remember',
    },

    'hy-AM': {
        store: 'Խանութ',
        main: 'Գլխավոր',
        box: 'Տուփ',
        business: 'Բիզնես',
        product: 'Տեսականի',
        aboutUs: 'Մեր մասին',
        contacts: 'Կապ',
        whoAreYou: 'Who are you',
        more: 'Ավելին',
        phone: 'Հեռախոս',
        address: 'հասցե',
        email: 'Էլ. հասցե',
        copyright: 'Բոլոր իրավունքները պաշտպանված են',
        order: 'Պատվիրել',
        currency: 'AMD',
        nameSurname: 'Անուն Ազգանուն',
        deliveryAddress: 'Առաքման Հասցե',
        comment: 'Մեկնաբանություն',
        notRequired: 'ոչ պարտադիր',
        send: 'ուղարկել',
        successMessage: 'Մեր վաճառքի մասնագետը շուտով կապ կհաստատի Ձեզ հետ:',
        thanks: 'Շնորհակալություն',
        starting: 'Սկսած',
        privacyConfirmMessage: 'Խնդրում ենք հաստատել, որ չափահաս եք՝ ապրանքանիշը տեսնելու համար։',
        ageConfirm: 'Ձեր 18 տարեկանը լրացե՞լ է',
        yes: 'Այո',
        no: 'Ոչ',
        remember: 'Հիշել',
    },
};
