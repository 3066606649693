import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import Header from './Header/Header';
import { Outlet, useLocation } from 'react-router-dom';
import Policy from '../components/Policy/Policy';
import { useQuery } from 'react-apollo';
import { GET_GLOBAL } from '../../server/global.query';
import disableScroll from 'disable-scroll';

export const GlobalContext = React.createContext({
    languageId: 'hy-AM',
    globalInfo: null,
});

const Layout = () => {
    const mainRef = useRef(null);
    const boxRef = useRef(null);
    const businessRef = useRef(null);
    const productRef = useRef(null);
    const aboutRef = useRef(null);
    const contactsRef = useRef(null);

    const [state, setState] = useState({
        languageId: 'hy-AM',
        globalInfo: null,
        refList: {
            mainRef,
            boxRef,
            businessRef,
            productRef,
            aboutRef,
            contactsRef,
        },
    });
    const [isChecked, setIsChecked] = useState(false);
    const [showLocalPolicy, setShowLocalPolicy] = useState(true);
    const { pathname } = useLocation();
    const handleConfirm = () => {
        if (isChecked) {
            localStorage.setItem('remember', 'true');
        }
        setShowLocalPolicy(false);
        disableScroll.off();
    };

    const showPolicy = localStorage.getItem('remember');

    const { data: global } = useQuery(GET_GLOBAL, {
        variables: { languageId: state.languageId },
        fetchPolicy: 'network-only',
    });

    const value = useMemo(() => {
        return {
            state,
            setState,
        };
    }, [state]);

    useEffect(() => {
        if (global) {
            setState({ ...state, globalInfo: global.global?.data?.attributes });
        }
    }, [global]);
    useLayoutEffect(() => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, [pathname]);

    return (
        <GlobalContext.Provider value={value}>
            <div className="layout">
                {!showPolicy && showLocalPolicy && (
                    <Policy
                        isChecked={isChecked}
                        setIsChecked={setIsChecked}
                        handleConfirm={handleConfirm}
                    />
                )}
                <Header />
                <Outlet />
            </div>
        </GlobalContext.Provider>
    );
};

export default Layout;
