import { gql } from 'apollo-boost';

export const GET_GLOBAL = gql`
    query Global($languageId: I18NLocaleCode) {
        global(locale: $languageId) {
            data {
                attributes {
                    contactPhone
                    address
                    email
                    logo {
                        data {
                            attributes {
                                url
                            }
                        }
                    }
                    socialLinks {
                        id
                        link
                        icon {
                            data {
                                attributes {
                                    url
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
