import React, { lazy, Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Layout from './views/layout/Layout';

import './style/styles.scss';

const Home = lazy(() => import('./views/pages/Home/Home'));
const Store = lazy(() => import('./views/pages/Store/Store'));
const StoreItem = lazy(() => import('./views/pages/StoreItem/StoreItem'));
const Custom = lazy(() => import('./views/pages/Custom/Custom'));

const App = () => (
    <Suspense fallback={null}>
        <Routes>
            <Route element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="store" element={<Store />} />
                <Route path="store/:id" element={<StoreItem />} />
                <Route path="custom" element={<Custom />} />
                <Route
                    path="*"
                    element={
                        <h1 className="vh-100 d-flex align-items-center justify-content-center">
                            404 NOT FOUND
                        </h1>
                    }
                />
            </Route>
        </Routes>
    </Suspense>
);

export default App;
