import styled from 'styled-components';
import pattern from '../../../assets/images/pattern.png';

export const HeaderStyled = styled.header`
    position: fixed;
    inset: 0 0 auto 0;
    z-index: 100;
    background-image: linear-gradient(180deg, #000000 0%, rgba(0, 0, 0, 0) 100%);
    padding: 60px 0;
    @media (min-width: 1280px) {
        padding: 20px 0;
    }

    @media (min-width: 1440px) {
        padding: 40px 0;
    }

    .header-wrapper {
        padding: 0 25px;
        @media (min-width: 768px) {
            padding: 0 50px;
        }
        @media (min-width: 1440px) {
            padding: 0 90px;
        }
    }

    .logo,
    .logo img {
        height: 57px;
        filter: brightness(2);
        transition: filter 0.2s ease-in-out;
        @media (min-width: 1280px) {
            &:hover {
                filter: brightness(1);
            }
        }

        @media (min-width: 1280px) {
            height: 64px;
        }
        //@media (min-width: 1440px) {
        //    height: 90px;
        //}
        //@media (min-width: 1920px) {
        //    height: 115px;
        //}
    }

    nav {
        position: fixed;
        inset: 0 0;
        background-color: #090909;
        padding: 0 25px;
        transform: translateX(100%);
        transition: transform 0.4s ease-in-out;
        overflow: hidden;

        li {
            font-size: 14px;
        }

        @media (min-width: 768px) {
            padding: 0 50px;
        }

        @media (min-width: 1280px) {
            position: static;
            background-color: transparent;
            padding: 10px 0;
            transform: translateX(0);
        }

        @media (max-width: 1219px) {
            &.active {
                transform: translateX(0);

                .pattern {
                    position: absolute;
                    top: 0;
                    right: 30px;
                    bottom: 0;
                    width: 140px;
                    height: 100%;
                    background: url(${pattern}) top / 300%;

                    @media (min-width: 768px) {
                        width: 200px;
                        right: 90px;
                    }
                    @media (min-width: 1280px) {
                        right: 150px;
                        width: 300px;
                    }
                    @media (min-width: 1440px) {
                        right: 170px;
                    }
                    @media (min-width: 1920px) {
                        right: 190px;
                        width: 400px;
                    }
                }
            }
        }

        .logo-container {
            position: relative;
            z-index: 3;
            margin-bottom: 60px;

            > img {
                width: 18px;
                filter: brightness(6);
            }
        }

        .button-outline {
            margin-left: -27px;
            margin-bottom: 25px;
        }

        ul {
            margin-bottom: 35px;
            @media (min-width: 1280px) {
                margin-bottom: 0;
            }

            li {
                font-weight: 200;
                letter-spacing: 2px;

                &:not(:last-child) {
                    margin-bottom: 25px;
                    @media (min-width: 1280px) {
                        margin-right: 20px;
                    }

                    @media (min-width: 1920px) {
                        margin-bottom: 0;
                        margin-right: 25px;
                    }
                }

                a {
                    color: #6b6b6b;
                    text-decoration: none;

                    &.avtive,
                    &:hover {
                        color: #fff;
                    }
                }

                &.active {
                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .button,
    .button-outline {
        width: 130px;
    }

    .hamburger {
        @media (min-width: 768px) {
            width: 40px;
        }
    }

    .languages {
        font-size: 12px;
        @media (min-width: 1280px) {
            margin-left: 50px;
        }

        @media (min-width: 1920px) {
            margin-left: 130px;
        }

        > div {
            margin-bottom: 25px;
            @media (min-width: 1280px) {
                margin-bottom: 0;
            }
            color: #6b6b6b;
            cursor: pointer;

            &.active,
            &:hover {
                color: #fff;
            }
        }
    }
`;
